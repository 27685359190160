import React from 'react';
import Img from 'gatsby-image'
import SwiperCore, { Pagination } from 'swiper';
import {
  Swiper, SwiperSlide
} from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);


const MobileSwiper = props => {
  return (
    <Swiper
      pagination={{ clickable: true }}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {props.slides.map(slide =>

        <SwiperSlide><Img fluid={slide} /></SwiperSlide>

      )}

    </Swiper>
  );
};

export default MobileSwiper