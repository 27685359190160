import React from "react"
import styled from "styled-components"

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
`

const Container = styled.div`
  max-width: 1028px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Copy = styled.p`
  font-size: 1rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: 1px;
  padding: 1rem;
`

const Phrase = styled.h4`
  font-family: "Courier New", Courier, monospace;
  font-size: ${props => props.size};
  font-weight: 700;
  margin-bottom: 2rem;
  color: #181818;
`

const Welcome = () => {
  return (
    <Wrapper>
      <Container>
        <Phrase size="1.15rem">Willkommen bei diewohnplaner.</Phrase>
        <Copy>
          Sie sind auf der Suche nach neuen Ideen für Ihr Zuhause oder
          ausgefallenen Designmöbel und Wohnaccessoires? Dann besuchen Sie uns
          in unserem Einrichtungshaus auf der Immermannstraße 6 in Düsseldorf!
          Lassen Sie sich von außergewöhnlichen Einrichtungsideen inspirieren
          oder verwirklichen Sie mit unseren Interior Designern Ihre Ideen für
          ein individuelles Zuhause.
        </Copy>
        <Phrase size="1.15rem">Wir freuen uns auf Sie!</Phrase>
      </Container>
    </Wrapper>
  )
}

export default Welcome
