import React from 'react';



import SwiperCore, { Pagination, Autoplay, Navigation, } from 'swiper';
import {
  Swiper, SwiperSlide
} from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import './../heroswiper.scss'

import HeroImg_Horizontal from '../../images/diewohnplaner-impression-eingangsbereich.jpg';
import HeroImg_Vertikal from '../../images/diewohnplaner-impression-eingangsbereich-vertikal.jpg';

import Dollhouse_Vertikal from '../../images/virtuelle-3d-austellung-grundriss-matterport-diewohnplaner-duesseldorf-vertikal.jpg';
import Dollhouse_Horizontal from '../../images/diewohnplaner-Dollhouse-view.jpg';

import  Kissen_Spezial from '../../images/kissen-spezial-diewohnplaner-duesseldorf-2.jpg'


import styled from 'styled-components';

import { HeroNewsWrapper, HeroNewsContainer, HeroLink, ThreeDWrapper, HeroSpan, HeroA, HeroB, HeroFlex } from '../../styles/IndexStyles';
import { CopyText, Subline, HeroParagraph, SpecialLine } from '../../styles/components/typography';

SwiperCore.use([Navigation, Pagination, Autoplay ]);


const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #1b2336;
`

const FlexItem = styled.div`
  flex: 1.5 0 500px;
`

const FlexText = styled.div`
  flex: 1 1 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #000;
`

const SpecialWrapper = styled.div`
  margin: 1rem;
  position: absolute; 
  top: -3.45rem;
  padding: 2rem;
  background-color: rgba(255,255,255,.9);
  border-radius: 1.5rem;
  box-shadow: 2px 2px 5px lightgrey;
  @media(min-width: 768px) {
    top: -100px;
  }
  @media(min-width: 835px) {
    top: unset;
    left: -100px;
    right: 100px; 
    box-shadow: none;
  }
`



const Hero = () => {

  return (
    <Swiper
      navigation={{ 
        clickable: true,
   
        }}
      pagination={{ 
        clickable: true,

       }}
       autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      loop={true}
      spaceBetween={50}
      slidesPerView={1}
    >
      {/* <SwiperSlide
      style={{
        cursor: "grab"
      }}
      >
        <picture>
          <source media="(max-width: 834px)" srcSet={HeroImg_Vertikal} />
          <source srcset={HeroImg_Horizontal} />
          <img width="100%" class="imgimg" src={HeroImg_Horizontal} alt="" />
        </picture>
        <HeroNewsWrapper>
          <HeroNewsContainer>
            <h2
            style={{
              fontSize: "1.5rem"
            }}
            ><strong>Click And Meet:</strong></h2>
            <HeroSpan> Vereinbaren Sie jetzt einen Termin:</HeroSpan>
        
            <HeroLink to="/termin-vereinbaren">Termin vereinbaren</HeroLink>
        
            <HeroSpan>Schreiben Sie uns eine E-Mail mit ihrem Wunschtermin:</HeroSpan>
            <HeroA href="mailto:info@diewohnplaner.de">E-Mail</HeroA> 
            <HeroSpan>Oder kommen Sie einfach vorbei!</HeroSpan>
          </HeroNewsContainer>
        </HeroNewsWrapper>
      </SwiperSlide> */}

      <SwiperSlide
      style={{
        cursor: "grab"
      }}>
       
        <picture>
          <source media="(max-width: 834px)" srcSet={HeroImg_Vertikal} />
          <source srcset={HeroImg_Horizontal} />
          <img width="100%" class="imgimg" src={HeroImg_Horizontal} alt="" />
        </picture>

        <ThreeDWrapper>
          <HeroB href="https://my.matterport.com/show/?m=CgJiMcfHXdm">Virtueller Rundgang der Ausstellung!</HeroB>
        </ThreeDWrapper>

      </SwiperSlide>

      <SwiperSlide>

        <Flex>

          <FlexItem>
            <img 
            style={{
              width: "100%"
            }}
          
            src={Kissen_Spezial} />
           
          </FlexItem>

          <FlexText>
            <SpecialWrapper>

            <SpecialLine>#PIMPYOURCOUCH</SpecialLine>

            <HeroParagraph></HeroParagraph>
            <CopyText>Holt Euch frische Farbe ins Wohnzimmer!
            </CopyText>
            <HeroParagraph>Freut Euch über ein individuell auf eure Couch abgestimmtes Dekopaket an Farbe und Design.</HeroParagraph>
            <a
            style={{
              color: "rgb(35,152,171)",
              fontWeight: 500

            }}
            href="https://www.instagram.com/p/CONLcG3M0J8/">Mehr Infos auf Instagram!</a>
            
            {/* <HeroParagraph>Und so funktioniert's:</HeroParagraph>

            <HeroParagraph>1. Couch 2. Wohnbereich mit Couch 3. Detailaufnahme Stoff / Leder</HeroParagraph>

            <HeroParagraph>Bilder per WhatsApp an: <strong>015236611286</strong></HeroParagraph>

            <HeroParagraph>Unser <strong>#PIMPYOURCOUCH-Team</strong> stellt Euch ein cooles Designpakte zusammen das in unserem Geschäft diewohnplaner abgeholt werden darf (#clickandcollect). Preis #PIMPYOURCOUCH -Paket: € 150,00</HeroParagraph> */}

            </SpecialWrapper>
            </FlexText>

        </Flex>

      </SwiperSlide>
    </Swiper>
  );
}

export default Hero;







