import React, { useState } from "react"
import { graphql } from "gatsby"

import styled from 'styled-components';
import {
  Section,
  CustomLi, CustomLink, Left, ImgRight, Flex
} from '../styles/IndexStyles';
import { motion } from 'framer-motion';
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/index/hero"
import DwpSection from "../components/index/dwpSection";
import SofaSection from "../components/index/sofaSection";
import Schreinerei from "../components/index/tischlereiSection";
import LeuchtenSection from "../components/index/leuchtenSection";


import { Headline, Goodbye } from "../styles/components/typography";

import Yonie from "../images/stuehle-yonie-armlehne-diewohnplaner-duesseldorf.jpg"
import Gua from "../images/couch-und-beistelltische-diewohnplaner-duesseldorf.jpg"
import Schreibtisch from "../images/schreibtische-system-180-40138-diewohnplaner-duesseldorf.jpg"
import Deko from "../images/accessoires-koi-kissen-diewohnplaner-duesseldorf.jpg"
import Teppich from "../images/teppiche-lysee-2-dunkel-vogelperspektive-diewohnplaner-duesseldorf.jpg"
import Sideboard from "../images/sideboards-system180-22906-diewohnplaner-duesseldorf.jpg"
import Regal from "../images/regale-system-180-doppelregal-diewohnplaner-duesseldorf.jpg"
import Garderobe from "../images/garderoben-paul-diewohnplaner-duesseldorf.jpg"
import Bett from "../images/betten-marco-diewohnplaner-duesseldorf.jpg"

import WhatsAppLogo from "../assets/WhatsApp_Logo"
import HomepageGrid from "../components/index/Homepage Grid/HomepageGrid";
import HomepageGridItem from "../components/index/Homepage Grid/HomepageGridItem";
import DKMH from "../components/index/Homepage Grid/DKMH";
import IFrame from "../components/iFrame";
import Welcome from "../components/index/Welcome";
import Promotion from "../components/index/Homepage Grid/Promotion";

const FloatingActionButton = styled(motion.button)`
position: fixed;
right: 0;
bottom: 0;
margin-right: 20px;
margin-bottom: 20px;
z-index: 9999;
width: 64px;
height: 64px;
background: none;
border: none;
cursor: pointer;
`

const FloatingLandeswappen = styled(motion.button)`
position: fixed;
left: 0;
bottom: 0;
margin-left: 20px;
margin-bottom: 20px;
z-index: 9999;
width: 64px;
height: 64px;
background: none;
border: none;
cursor: pointer;
`



const IndexPage = ({ data }) => {
  // const [img, setImg] = useState(Yonie)

  const content = data.allWpPage.nodes[0]
  const categories = content.pageTypeIndexFields.categories

  // Notlösung

  const HeroImg = content.pageTypeIndexFields.heroimg.localFile.childImageSharp.fluid;

  const title = content.pageTypeIndexFields.categories.map(c => c.title);
  const img = content.pageTypeIndexFields.categories.map(c => c.img.localFile.childImageSharp.fluid);
  const link = content.pageTypeIndexFields.categories.map(c => c.link.slug);
  const color = content.pageTypeIndexFields.categories.map(c => c.backgroundcolor);

  const promotion = content.pageTypeIndexFields.promotion;
  const promotionTest = content.pageTypeIndexFields.promotion.map(p => p.title);
  // const image = categories.map(c => c.img.localFile.childImageSharp.fluid)
  // // const actualImage = image.map(i => i.localFile);


  // const dwpSlides = content.pageTypeIndexFields.sectionDiewohnplaner.images.map(i => i.localFile)
  // const dwpSlides2 = dwpSlides.map(i => i.childImageSharp)
  // const dwpSlides3 = dwpSlides2.map(i => i.fluid)
  // const sofaSlides = content.pageTypeIndexFields.sectionsofas.images.map(i => i.localFile)
  // const sofaSlides2 = sofaSlides.map(i => i.childImageSharp)
  // const sofaSlides3 = sofaSlides2.map(i => i.fluid)

  const sources = [
    content.pageTypeIndexFields.mobileimage.localFile.childImageSharp.fluid,
    {
      ...content.pageTypeIndexFields.heroimg.localFile.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <SEO title="Das kreative Möbelhaus in Düsseldorf" />
      <div
        style={{
          backgroundColor: "#fff"
        }}>
        <HomepageGrid>

          {/* hier mappen */}

          <DKMH />

          {/* {categories.map(c =>
            <HomepageGridItem
              id="sofas"
              name={c.title}
              img={c.img.localFile.childImageSharp.fluid}
              link={c.link.slug}
              color={c.backgroundcolor}
              gridArea={c.title}
            />
          )} */}

          {promotion.map(p =>
            <Promotion
            name={p.title}
            link={p.instagramlink}
            img={p.img.localFile.childImageSharp.fluid}
            text={p.bodycopy} />
  
          )}

          <HomepageGridItem
            name={title[0]}
            img={img[0]}
            link={link[0]}
            color={color[0]}
            gridArea={title[0]}
          />

          <HomepageGridItem
            name={title[1]}
            img={img[1]}
            link="stuehle"
            color={color[1]}
            gridArea={title[1]}
          />

          <HomepageGridItem
            name={title[2]}
            img={img[2]}
            link={`/sofas/${link[2]}`}
            color={color[2]}
            gridArea={title[2]}
          />

          <HomepageGridItem
            name={title[3]}
            img={img[3]}
            link={link[3]}
            color={color[3]}
            gridArea={title[3]}
          />

          <HomepageGridItem
            name={title[4]}
            img={img[4]}
            link={`/accessoires/${link[4]}`}
            color={color[4]}
            gridArea={title[4]}
          />


          <HomepageGridItem
            name={title[5]}
            img={img[5]}
            link={`/accessoires/${link[5]}`}
            color={color[5]}
            gridArea={title[5]}
          />


          <HomepageGridItem
            name={title[6]}
            img={img[6]}
            link={link[6]}
            color={color[6]}
            gridArea={title[6]}
          />


          <HomepageGridItem
            name={title[7]}
            img={img[7]}
            link={link[7]}
            color={color[7]}
            gridArea={title[7]}
          />

        
          
          



        </HomepageGrid>

        {/* <div 
        style={{
          width: "100%",
          height: "100%",
        }}> */}

        <IFrame 
        img={sources}
        />
        {/* </div> */}

        <Welcome />

        {/* <FloatingActionButton>
      <a href="https://api.whatsapp.com/send?phone=4915236611286" title="Senden Sie uns eine Nachricht über WhatsApp!" target="_blank" rel="noreferrer noopener">
      <WhatsAppLogo />
      </a>
        
      </FloatingActionButton> 

   <iframe src="https://my.matterport.com/show/?m=CgJiMcfHXdm" allow="xr-spatial-tracking" width="1050'" height="590'" frameborder="0" />

      <Hero />

      <DwpSection
        headline={content.pageTypeIndexFields.sectionDiewohnplaner.headline}
        subline={content.pageTypeIndexFields.sectionDiewohnplaner.subline}
        body={content.pageTypeIndexFields.sectionDiewohnplaner.passages}
        img1={dwpSlides3[0]}
        img2={dwpSlides3[1]}
        slides={dwpSlides3}
      />

      <SofaSection
        headline={content.pageTypeIndexFields.sectionsofas.headline}
        body={content.pageTypeIndexFields.sectionsofas.passages}
        img1={sofaSlides3[0]}
        img2={sofaSlides3[1]}
      />

      <Schreinerei
        headline={content.pageTypeIndexFields.sectiontischlerei.headline}
        body={content.pageTypeIndexFields.sectiontischlerei.passages}
        img={content.pageTypeIndexFields.sectiontischlerei.img.localFile.childImageSharp.fluid}
        bulletpoints={content.pageTypeIndexFields.sectiontischlerei.bulletpoints}
      />

     <LeuchtenSection
        headline={content.pageTypeIndexFields.sectionleuchten.headline}
        body={content.pageTypeIndexFields.sectionleuchten.passages.map(passage => passage.passage)}
        // backgroundImg={content.pageTypeIndexFields.leuchten.backgroundImg.localFile.childImageSharp.fluid}
        images={content.pageTypeIndexFields.sectionleuchten.images}
      /> 

      <Section>
        <Flex>
          <Left>
         <Headline>Weitere Kategorien</Headline>
         <ul>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Yonie)}  to="/stuehle">Stühle</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Gua)}  to="/tische/couch-und-beistelltische">Couch- und Beistelltische</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Schreibtisch)}  to="/tische/schreibtische">Schreibtische</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Deko)}  to="/accessoires/dekoration">Dekoration</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Teppich)}  to="/accessoires/teppiche">Teppiche</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Sideboard)} to="/aufbewahrung/sideboards">Sideboards</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Regal)} to="/aufbewahrung/regale">Regale</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Garderobe)}  to="/aufbewahrung/garderoben">Garderoben</CustomLink></CustomLi>
           <CustomLi><CustomLink onMouseEnter={() => setImg(Bett)}  to="/betten">Betten</CustomLink></CustomLi>
         </ul>
         </Left>

         <ImgRight>
           <img width="100%"  src={img} alt="" /> 
         </ImgRight>
         
        </Flex>

      </Section>

      <Section>
        <Goodbye>Besuchen Sie uns in unserem Geschäft. Wir helfen Ihnen gern dabei, genau das Richtige für Sie zu finden.</Goodbye>
      </Section> */}

      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query {
  allWpPage(filter: {title: {eq: "Index"}}) {
    nodes {
      pageTypeIndexFields {
        categories {
          title
          backgroundcolor
          link {
            ... on WpPage {
              slug
            }
          }
          img {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        heroimg {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        mobileimage {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        welcomemessage
        promotion {
          title
          instagramlink
          bodycopy
          img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
}
`