import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Wrapper = styled.div`
  background-color: #f7f7f7;
  padding: 1rem;
`
const Grid = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  /* padding: 0 1rem 0 1rem; */
  margin-bottom: 1rem;
  // change to 100% after schema extension
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "polaroid polaroid"
    "polaroid polaroid"
    "polaroid polaroid"
    "polaroid polaroid"
    "polaroid polaroid"
    "polaroid polaroid"
    "polaroid polaroid"
    "Sofas Sofas"
    "Sofas Sofas"
    "Sofas Sofas"
    "wp wp"
    "Stühle Sessel"
    "Stühle Sessel"
    "Stühle Sessel"
    "Tische Tische"
    "Tische Tische"
    "Tische Tische";
  grid-gap: 0.75rem;

  // Tablet
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "Sofas Sofas polaroid polaroid"
      "Sofas Sofas polaroid polaroid"
      "wp wp polaroid polaroid"
      "Stühle Sessel Tische Tische"
      "Stühle Sessel Tische Tische"
      "Aufbewahrung Aufbewahrung Leuchten Dekoration"
      "Aufbewahrung Aufbewahrung Leuchten Dekoration"
      ". . Einrichtungsberatung Einrichtungsberatung"
      ;
  }

  // Desktop
  @media (min-width: 1028px) {
    height: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "Sofas Sofas Aufbewahrung Aufbewahrung polaroid polaroid"
      "Sofas Sofas Leuchten Dekoration polaroid polaroid"
      "wp wp Leuchten Dekoration polaroid polaroid"
      "wp wp Leuchten Dekoration polaroid polaroid"
      "Stühle Sessel Tische Tische polaroid polaroid"
      "Stühle Sessel Tische Tische Einrichtungsberatung Einrichtungsberatung"
  }
`

const HomepageGrid = ({ children }) => {
  return <Wrapper><Grid>{children}</Grid></Wrapper>
}

export default HomepageGrid
