import * as React from "react"

function Playbutton(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" width="48px" {...props}>
      <path d="M102.42 65.87l-53.77-31A7.07 7.07 0 0038 41v62.1a7.07 7.07 0 0010.61 6.12l53.77-31a7.08 7.08 0 00.04-12.35z" />
    </svg>
  )
}

export default Playbutton
