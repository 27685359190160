import styled from 'styled-components'
import { Link } from "gatsby"
import { motion } from 'framer-motion'

export const Section = styled(motion.section)`
height: 100%;
margin-top: 3rem;
@media (min-width: 1280px) {
    margin-top: 5rem;
margin-bottom: 5rem;
}
`

export const ImgContainer = styled(motion.div)`
width: 100%;
`

// Sofas

export const ImgWrapper = styled(motion.div)`
    width: 100%;
`

export const ImgWrapper2 = styled(motion.div)`
    width: 60%;
    margin: 0 auto;
`

export const Label = styled(motion.label)`
    font-size: 0.8rem;
    color: #000000;
    letter-spacing: 2px;
`

export const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    @media (min-width: 1280px) {
        margin-bottom: 4rem;
}
`

export const DwpLeft = styled(motion.div)`
    display: none;
    @media (min-width: 1280px) {
    display: unset;
    flex: 1 620px;
}
`

export const DwpRight1 = styled(motion.div)`
    display: none;
    @media (min-width: 1280px) {
    display: unset;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}
`

export const ApeLeft = styled.div`
    flex: 1 620px;
    display: flex;
    justify-content: center;
    background-color: #AEE8E4;
`

export const DogWrapper = styled(motion.div)`
    display: none;
    @media (min-width: 1280px) {
    display: block;
    width: 100%
}

`

export const Left = styled(motion.div)`
    flex: 1 620px;
    padding: 26px;
`

export const Right = styled(motion.div)`
    flex: 1 620px;
    padding: 26px;
`

export const ImgRight = styled.div`
    display: none;
    
    @media (min-width: 1280px) {
    display: block;
    flex: 1 620px;
}
`

export const DogRight = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 720px;
    padding: 26px;
`

export const Left1 = styled(motion.div)`
    display: block;
    flex: 1;
    padding: 26px;
`

export const Right1 = styled(motion.div)`
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`


export const CustomLi = styled.li`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
`

export const CustomLink = styled(Link)`
    color: #6E36CA;
    font-size: 1.6rem;
    /* &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.314' height='16' viewBox='0 0 13.314 24.626'%3E%3Cg id='Gruppe_262' data-name='Gruppe 262' transform='translate(-46.25 76.563) rotate(180)'%3E%3Cpath id='Pfad_86' data-name='Pfad 86' d='M-47.25,76.563a1,1,0,0,1-.707-.292L-59.271,64.957a1,1,0,0,1,0-1.414l11.314-11.314a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.415L-57.149,64.25l10.606,10.606a1,1,0,0,1,0,1.415A1,1,0,0,1-47.25,76.563Z'/%3E%3C/g%3E%3C/svg%3E");
 
    } */
    :hover {
        color: #B9789F;
    }
    
`

export const Header = styled.header`
    text-align: center;
    margin-bottom: 64px;
`



export const TischLeft = styled.div`
    flex: 1 1 250px;
    order: 2;
    padding: 26px;
    @media (min-width: 1280px) {
        order: 1;
    }   
`
export const TischRight = styled(motion.div)`
    flex: 1 1 400px;
    order: 1;
    @media (min-width: 1280px) {
        order: 2;
    }`


export const HeroNewsWrapper = styled.div`
          padding: 26px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          @media(min-width: 834px) {
           
          }
`

export const HeroNewsContainer = styled.div`
    padding: 2rem;
    background-color: rgba(255, 255, 255, .9);
    border-radius: .5rem;
    @media(min-width: 834px) {
           
    }
`

export const HeroLink = styled(Link)`
    display: inline-block;
    padding: 1rem;
    border: 1px solid #D5D5D5;
    border-radius: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    :hover {
        color: #fff;
        background: #D5D5D5;
    }
    @media(min-width: 834px) {
           
    }
`

export const HeroA = styled.a`
    display: inline-block;
    padding: 1rem;
    border: 1px solid #D5D5D5;
    border-radius: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    :hover {
        color: #fff;
        background: #D5D5D5;
    }
`

export const HeroB = styled.a`
    display: inline-block;
    padding: 2rem;
    border: 1px solid #D5D5D5;
    border-radius: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    color: #000;
    background-color: rgba(255,255,255,.9);
    font-size: 1rem;
    :hover {
        color: #fff;
        background: #D5D5D5;
    }
`

export const HeroSpan = styled.div`
    margin-top: .5rem;
    margin-bottom: .5rem;
`

export const ThreeDWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 10%;
    text-align: center; 
    @media (min-width: 834px) {
        top: 42.5%;

    }  
`

// export const HeroFlex = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `