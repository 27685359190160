import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

const Item = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: wp;
  background-color: inherit;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
`

const Title = styled.h1`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.55rem;
  font-weight: 700;
`

const ItemBottom = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
`

const CustomLink = styled(Link)`
  font-size: 12px;
  :hover {
    :after {
      content: ">";
    }
  }
`

const DKMH = () => {
  return (
    <Item>
      <ImageWrapper>
        <Title>
          Das kreative Möbelhaus <br /> in Düsseldorf
        </Title>
      </ImageWrapper>
      <ItemBottom>
        <CustomLink to="/einrichtungshaus">Einrichtungshaus</CustomLink>
      </ItemBottom>
    </Item>
  )
}

export default DKMH
