import React, { useState, useEffect } from 'react';

import Img from "gatsby-image"
import styled from 'styled-components';
import {
    Section,
    Header,
    Flex,
    DwpLeft,
    Right,
    Left1,
    DwpRight1,
    CustomLink,
    HeroLink,
    HeroA
} from '../../styles/IndexStyles'
import { 
    CopyText, 
    Headline, 
    Subline,

} from '../../styles/components/typography'
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import MobileSwiper from './../MobileSwiper';


const CarouselContainer = styled.div`
    display: block;
    @media (min-width: 600px) {
        display: none;
    }
`


export const GhostButton = styled.button`
    border: none;
    border-radius: 50px;
    background-color: #B9789F;
    color: #fff;
    font-size: 1.1rem;
    padding: 18px;
    align-items: center;
    width: 60%;
    align-self: center;
    margin-top: 3rem;

`

const CustomLi = styled.li`
    margin-bottom: 1rem;
`



const variantsLeft = {
    hidden: {
        opacity: 0,
        x: -100,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {

            duration: 1.6
        }
    }
}

const variantsRight = {
    hidden: {
        opacity: 0,
        x: 100,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {

            duration: 1.6
        }
    }
}



const DwpSection = props => {
    const [doc, setDoc] = useState();

    useEffect(() => setDoc(document));
    const controls = useAnimation();
    const [ref, inView] = useInView({
        root: doc,
        rootMargin: '0px',
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <Section
            ref={ref}
            animate={controls}
        >
            <Header>
                <Headline>{props.headline}</Headline>
                <Subline>{props.subline}</Subline>
            </Header>
            <CarouselContainer>
                <MobileSwiper
                slides={props.slides}
                 />
            </CarouselContainer>
            <Flex>
                <DwpLeft variants={variantsLeft} initial="hidden">
                    <Img fluid={props.img1} alt="" />
                </DwpLeft>
                <Right>
             
                    <CopyText >
                        {props.body.map(i => i.passage)}
                    </CopyText>
                    
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%"
                    }}>
                     <HeroA href="mailto:info@diewohnplaner.de">Jetzt Termin vereinbaren</HeroA>
                    <HeroA href="https://my.matterport.com/show/?m=CgJiMcfHXdm">Virtueller Rundgang!</HeroA> 
                    </div>
                    
                </Right>
            </Flex>
            <Flex>
                <Left1>
                
                    <CopyText >
                    Wir helfen Ihnen, moderne Wohntrends oder klassische Raumgestaltungen innovativ umzusetzen. Unsere Einrichtungsplaner zeigen Ihnen Konzepte, die zu Ihren Wohnvorstellungen sowie zu Ihren Vorlieben in Bezug auf Farben und Formen passen.
                    </CopyText>
                    <ul style={{width: "100%"}}>
                    <CustomLi>
                     <CustomLink to="/einrichtungsplanung">
                    Einrichtungsplanung
                    </CustomLink>   
                    </CustomLi>
                    <CustomLi>
                    <CustomLink
                    to="/ueber-diewohnplaner"
                    >
                    Einrichtungshaus
                    </CustomLink> 
                    </CustomLi>
                    </ul>
                
                </Left1>
                <DwpRight1 variants={variantsRight} initial="hidden" >
                    <Img fluid={props.img2} alt=""/>
                </DwpRight1>
            </Flex>

            {/* <Flex>
                <DwpLeft>Bild</DwpLeft>
                <Right>Zur Austellung</Right>
            </Flex> */}
           
        </Section>);
}

export default DwpSection;