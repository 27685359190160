import React from 'react';
import Img from "gatsby-image"
import { useState } from 'react';
import styled from 'styled-components';
import Playbutton from '../assets/Playbutton';

const Wrapper = styled.div`
   
    width: 100%;
    position: ${props => props.isFullscreen ? "unset" : "relative"};
    display: ${props => props.isFullscreen ? "block" : "none"};
`

const Frame = styled.iframe`
    position: ${props => props.isFullscreen ? "fixed" : "unset"};
    top: ${props => props.isFullscreen ? 0 : "unset"};
    left: ${props => props.isFullscreen ? 0 : "unset"};
    height: ${props => props.isFullscreen ? "100vh" : "590px"};
    background-color: inherit;
    z-index: 9998;
`

const FullscreenButton = styled.button`
    position: ${props => props.isFullscreen ? "fixed" : "absolute"};
    top: 10px;
    right: 10px;
    z-index: 9999;
    background: #fff;
    border: none;
    padding: .5rem 1rem;
    cursor: pointer;
`

const ImageWrapper = styled.div`
    position: relative;
`

const Overlay = styled.div`
     width: 75%;
    height: 50%;
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(233, 233, 233, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media(min-width: 768px) {
        width: 25%;
    height: 75%;
    }
    
`

const PlayButtonWrapper = styled.div`
    height: 80%;
`

const PlayButton = styled.div`
    border-radius: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    background-color: #fff;
`

const Text = styled.p`
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
    font-size: 1.5rem;
    color: black;

`


const IFrame = (props) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    return ( 
        <>

        <ImageWrapper>
        <Img fluid={props.img} />

        <Overlay onClick={() => setIsFullscreen(true)}>
            
            <PlayButton><Playbutton /></PlayButton>

        <Text>360° Austellung</Text>
        </Overlay>

        </ImageWrapper>

        <Wrapper isFullscreen={isFullscreen}>
        <Frame src="https://my.matterport.com/show/?m=1v8LS1X55x4" allow="xr-spatial-tracking" width="100%" height="390'" frameborder="0" isFullscreen={isFullscreen} />
        <FullscreenButton onClick={() => setIsFullscreen(false)} isFullscreen={isFullscreen}>Vollbild schließen</FullscreenButton>
        </Wrapper>
        </>

     );
}
 
export default IFrame;