import styled from "styled-components"
import { motion } from "framer-motion";


export const Head = styled(motion.header)`
    text-align: left;
    width: 100%;
`

export const CustomLi = styled.li`
    margin-bottom: 1rem;
`

export const Headline = styled.h2`
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: 2px;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`

export const CopyText = styled(motion.p)`  
    line-height: 1.8rem;
    font-weight: 300;
    letter-spacing: 1.2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
`

export const Subline = styled.h3`
    font-weight: 200;
    font-size: 1.4rem;
    letter-spacing: 1px;
`

export const Goodbye = styled.h3`
    text-align: left;
    font-weight: 500;
    font-size: 1.5rem;
    margin-right: 26px;
    margin-left: 26px;
    @media (min-width: 1280px) {
    text-align: center;
    font-size: 1.5rem;
    margin-right: 15%;
    margin-left: 15%;
}
`


export const HeroParagraph = styled.p`
    line-height: 1.7rem;

    letter-spacing: 1.2px;
    font-size: .875rem;
    margin-bottom: .5rem;
`

export const SpecialLine = styled.h2`
    font-weight: 600;
    font-size: 2rem;
    color: #000;
`

export const NewCopy= styled.p`
    font-size: .9rem;
    line-height: 2rem;
    letter-spacing: 1.25px;
    padding: 1rem 1rem 2rem 1rem;
    color: black;
    max-width: 70ch;
`