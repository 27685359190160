import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Item = styled.a`
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  grid-area: polaroid;
  background-color: #ffffff;
  transition: 0.35s;
  :hover {
    transform: rotate(0.5deg);
    box-shadow: 1px 2px 5px #6d6e71;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
`

const ItemBottom = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 2rem 2rem 2rem;
`

const PromoTitle = styled.div`
  font-weight: 700;
  font-size: 1rem;
  display: block;
`

const Promotext = styled.p`
  font-size: .75rem;
`

const Promotion = props => {
  return (
    <Item target="_blank" rel="noopener noreferrer" href={props.link}>
      <ImageWrapper>
        <Img
          style={{ width: "100%" }}
          fluid={props.img}
          imgStyle={{ objectFit: "contain" }}
        />
      </ImageWrapper>

      <ItemBottom>
        <PromoTitle>{props.name}</PromoTitle>
        <Promotext>{props.text}</Promotext>
      </ItemBottom>
    </Item>
  )
}

export default Promotion
