import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"

// other tag?
// fixed width and height are angebracht

const Item = styled(Link)`
  cursor: pointer;
  display: ${props =>
    props.gridArea === "Aufbewahrung"
      ? "none"
      : props.gridArea === "Dekoration"
      ? "none"
      : props.gridArea === "Leuchten"
      ? "none"
      : props.gridArea === "Einrichtungsberatung"
      ? "none"
      : "flex"};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  grid-area: ${props => props.gridArea};
  background-color: ${props => props.color};
  transition: 0.35s;
  :hover {
    transform: rotate(0.5deg);
    box-shadow: 1px 2px 5px #6d6e71;
  }
  @media (min-width: 768px) {
    display: ${props =>
      props.gridArea === "aufbewahrung"
        ? "flex"
        : props.gridArea === "art"
        ? "flex"
        : props.gridArea === "leuchten"
        ? "flex"
        : props.gridArea === "planung"
        ? "flex"
        : "flex"};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
`

const Image = styled(Img)``

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
  padding: 1rem;
`

const ItemBottom = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
`

const CustomLink = styled(Link)`
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
`

const HomepageGridItem = ({ name, link, img, color, gridArea }) => {
  return (
    <Item gridArea={gridArea} color={color} 
    to={link}
    >
      <ImageWrapper>
          <Img style={{width: "75%"}} fluid={img} imgStyle={{ objectFit: 'contain' }} />
      </ImageWrapper>

      <ItemBottom>
        <CustomLink to={link}>{name}</CustomLink>
      </ItemBottom>
    </Item>
  )
}

export default HomepageGridItem
